import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import styles for date picker

const DateRangePicker = ({ startDate, endDate, onChange, className }) => {
  const formatDate = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}`;
  };

  return (
    <div className={`relative w-full ${className}`}>
      <DatePicker
        selected={startDate ? new Date(startDate) : null}
        onChange={(dates) => {
          const [start, end] = dates;
          onChange({ startDate: formatDate(start), endDate: formatDate(end) });
        }}
        startDate={startDate ? new Date(startDate) : null}
        endDate={endDate ? new Date(endDate) : null}
        selectsRange
        dateFormat="MMM yyyy"
        showMonthYearPicker
        className="border border-gray-300 rounded-sm shadow-sm pl-5 pr-2 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 w-full text-center placeholder-gray-400"
        placeholderText="Select date range"
      />
      <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500">📅</span>
    </div>
  );
};

export default DateRangePicker;
