import React from 'react';

const Sidebar = ({
    isSidebarOpen,
    activeMenu,
    handleMenuClick,
    dataCaptureOpen,
    toggleDataCapture,
    reportsOpen,
    toggleReports,
}) => (
    <aside
        id="sidebar"
        className={`fixed top-0 left-0 lg:relative lg:block bg-[#34495E] text-white w-60 border-r border-[#2C3E50] h-full transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
            } transition-transform duration-300 lg:translate-x-0`}
        aria-label="Sidebar"
    >
        <div className="overflow-y-auto py-4 px-2 md:px-3 h-full">
            <ul className="space-y-2">
                {/* Dashboard */}
                <li>
                    <div
                        onClick={() => handleMenuClick('dashboard')}
                        className={`flex items-center cursor-pointer p-2 text-base font-normal ${activeMenu === 'dashboard' ? 'bg-[#1ABC9C] text-white' : 'text-gray-200'
                            } rounded-lg hover:bg-[#16A085]`}
                    >
                        <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20">
                            <path d="M10 2a8 8 0 100 16 8 8 0 000-16z" fillRule="evenodd" clipRule="evenodd"></path>
                        </svg>
                        <span className="ml-1">Dashboard</span>
                    </div>
                </li>

                {/* Data Capture Menu */}
                <li>
                    <div
                        onClick={toggleDataCapture}
                        className="flex items-center justify-between cursor-pointer p-2 text-base font-normal text-gray-200 rounded-lg hover:bg-[#16A085]"
                    >
                        <div className="flex items-center">
                            <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5z"></path>
                            </svg>
                            <span className="ml-1">Data Capture</span>
                        </div>
                        <svg
                            className={`w-5 h-5 text-white transition-transform duration-200 ${dataCaptureOpen ? 'rotate-180' : ''
                                }`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"></path>
                        </svg>
                    </div>
                    {dataCaptureOpen && (
                        <ul className="pl-8 space-y-2">
                            <li>
                                <div
                                    onClick={() => handleMenuClick('grading')}
                                    className={`block px-4 py-2 rounded-lg hover:bg-[#16A085] cursor-pointer ${activeMenu === 'grading' ? 'bg-[#1ABC9C]' : ''
                                        }`}
                                >
                                    Grading Form
                                </div>
                            </li>
                        </ul>
                    )}
                </li>

                {/* Reports Menu */}
                <li>
                    <div
                        onClick={toggleReports}
                        className="flex items-center justify-between cursor-pointer p-2 text-base font-normal text-gray-200 rounded-lg hover:bg-[#16A085]"
                    >
                        <div className="flex items-center">
                            <svg className="w-6 h-6 text-white" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M3 4a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H4a1 1 0 01-1-1V4z"></path>
                            </svg>
                            <span className="ml-1">Reports</span>
                        </div>
                        <svg
                            className={`w-5 h-5 text-white transition-transform duration-200 ${reportsOpen ? 'rotate-180' : ''
                                }`}
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"></path>
                        </svg>
                    </div>
                    {reportsOpen && (
                        <ul className="pl-4 space-y-2">
                            <li>
                                <div
                                    onClick={() => handleMenuClick('school-report')}
                                    className={`block px-4 py-2 rounded-lg hover:bg-[#16A085] cursor-pointer ${activeMenu === 'school-report' ? 'bg-[#1ABC9C]' : ''
                                        }`}
                                >
                                    Schools Report
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => handleMenuClick('transaction-report')}
                                    className={`block px-4 py-2 rounded-lg hover:bg-[#16A085] cursor-pointer ${activeMenu === 'transaction-report' ? 'bg-[#1ABC9C]' : ''
                                        }`}
                                >
                                    Transactions Report
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => handleMenuClick('transaction-summary')}
                                    className={`block px-4 py-2 rounded-lg hover:bg-[#16A085] cursor-pointer ${activeMenu === 'transaction-summary' ? 'bg-[#1ABC9C]' : ''
                                        }`}
                                >
                                    Transactions Summary
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => handleMenuClick('remittance-summary')}
                                    className={`block px-4 py-2 rounded-lg hover:bg-[#16A085] cursor-pointer ${activeMenu === 'remittance-summary' ? 'bg-[#1ABC9C]' : ''
                                        }`}
                                >
                                    Remittance Report
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => handleMenuClick('revenue-performance')}
                                    className={`block px-4 py-2 rounded-lg hover:bg-[#16A085] cursor-pointer ${activeMenu === 'revenue-performance' ? 'bg-[#1ABC9C]' : ''
                                        }`}
                                >
                                    Revenue Performance
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => handleMenuClick('profile-performance')}
                                    className={`block px-4 py-2 rounded-lg hover:bg-[#16A085] cursor-pointer ${activeMenu === 'profile-performance' ? 'bg-[#1ABC9C]' : ''
                                        }`}
                                >
                                    Profile Performance
                                </div>
                            </li>
                            <li>
                                <div
                                    onClick={() => handleMenuClick('station-performance')}
                                    className={`block px-4 py-2 rounded-lg hover:bg-[#16A085] cursor-pointer ${activeMenu === 'station-performance' ? 'bg-[#1ABC9C]' : ''
                                        }`}
                                >
                                    Station Performance
                                </div>
                            </li>
                        </ul>
                    )}
                </li>
            </ul>
        </div>
    </aside>
);

export default Sidebar;
