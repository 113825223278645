import React from 'react';

const RevenueCard = ({ title, total, cash, card, index }) => {
    // Format numbers as currency
    const formatCurrency = (value) =>
        new Intl.NumberFormat('en-NG', {
            style: 'currency',
            currency: 'NGN',
        }).format(value);

    return (
        <div
            className={` ${
                index === 1
                    ? `bg-custom-green`
                    : `${
                          index === 2
                              ? `bg-custom-yellow`
                              : `${index === 3 ? `bg-custom-redish` : `bg-custom-blue`}`
                      }`
            } shadow-md border border-gray rounded-lg p-3 mb-4`}
        >
            <h3 className="text-xl font-semibold mb-2 text-white">{title}</h3>
            <p className="text-xl font-semibold text-white mb-4">{formatCurrency(total)}</p>
            <div className="flex justify-between">
                <div className="text-white">
                    <p className="font-medium">CASH</p>
                    <p>{formatCurrency(cash)}</p>
                </div>
                <div className="text-white">
                    <p className="font-medium">CARD</p>
                    <p>{formatCurrency(card)}</p>
                </div>
            </div>
        </div>
    );
};

export default RevenueCard;
