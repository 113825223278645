import React, { useEffect, useState, useCallback } from 'react';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import app from '../firebase/firebase';
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaDownload } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import SkeletonLoading from './SkeletonLoading';
import { useSyncLga } from './SyncLgaContext';

const db = getFirestore(app);

const SchoolReport = () => {
    const syncLga = useSyncLga();
    const [records, setRecords] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(false);

    const getTotalRecords = useCallback(async () => {
        try {
            const cachedRecords = localStorage.getItem('grading');
            if (cachedRecords) {
                const cachedGrading = JSON.parse(cachedRecords);
                if (cachedGrading.length !== 0) {
                    setRecords(cachedGrading);
                    setIsLoading(false);
                } else {
                    await fetchData();
                }
            } else {
                await fetchData();
            }
        } catch (error) {
            console.error('Error fetching or parsing cached records:', error);
            await fetchData();
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        getTotalRecords();
    }, [getTotalRecords]);

    const fetchData = async () => {
        setIsLoading(true);
        const querySnapshot = await getDocs(collection(db, 'grading'));
        const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setRecords(data);
        setIsLoading(false);
        localStorage.setItem('grading', JSON.stringify(data));
    };

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = records.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(records.length / recordsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handlePageSizeChange = (event) => {
        setRecordsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const getLgaNameById = (id) => {
        const lga = syncLga.find((lga) => lga.id === id);
        return lga ? lga.name : id;
    };

    const csvData = records.map((record, index) => ({
        SN: index + 1,
        'School Names': record.school_names,
        'Previous School Names': record.prev_school_names,
        Address: record.school_address,
        'Year Of Establishment': record.year_of_establishment,
        'Annual Renewal Year 2021': record.annual_renewal_year2021,
        'Annual Renewal Year 2022': record.annual_renewal_year2022,
        'Annual Renewal Year 2023': record.annual_renewal_year2023,
        LGA: getLgaNameById(record.lga_id),
        'School Email': record.school_email,
        'School Phone': record.school_phone_nos,
        'Proprietors Name': record.proprietors_name,
        'No. Of Female Learners': record.total_girls_enrolment,
        'No Of Male Learners': record.total_boys_enrolment,
        'No Learners':
            (parseInt(record.total_girls_enrolment) || 0) +
            (parseInt(record.total_boys_enrolment) || 0),
        'No. Of Female Teachers': record.no_teachers_female,
        'No Of Male Teachers': record.no_teachers_male,
        'No. Of Teachers (PWD)': record.no_teachers_pwd,
    }));

    if (isLoading) return <SkeletonLoading />;

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-xl font-bold mb-4">School Report</h2>

            <div className="flex justify-between items-center mb-4">
                <CSVLink
                    data={csvData}
                    filename="school_report.csv"
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center"
                >
                    <FaDownload className="mr-2" />
                    Download CSV
                </CSVLink>
            </div>

            <div className="overflow-x-auto">
            <table className="whitespace-nowrap min-w-full bg-white border border-gray-300 shadow-md text-sm">
                    <thead className="bg-gray-100">
                        <tr>
                            {[
                                'S/N',
                                'School Names',
                                'Previous School Names',
                                'Address',
                                'Year Of Establishment',
                                'Annual Renewal Year 2021',
                                'Annual Renewal Year 2022',
                                'Annual Renewal Year 2023',
                                'L.G.A',
                                'School Email',
                                'School Phone',
                                'Proprietors Name',
                                'No. Of Female Learners',
                                'No Of Male Learners',
                                'No Learners',
                                'No. Of Female Teachers',
                                'No Of Male Teachers',
                                'No. Of Teachers (PWD)',
                            ].map((header, index) => (
                                <th
                                    key={index}
                                    className="py-2 px-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider border-b"
                                >
                                    {header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {currentRecords.map((record, index) => (
                            <tr
                                key={record.id}
                                className={`hover:bg-gray-100 ${
                                    index % 2 === 0 ? 'bg-gray-50' : 'bg-white'
                                }`}
                            >
                                <td className="py-2 px-3">{(currentPage - 1) * recordsPerPage + index + 1}</td>
                                <td className="py-2 px-3">{record.school_names}</td>
                                <td className="py-2 px-3">{record.prev_school_names}</td>
                                <td className="py-2 px-3">{record.school_address}</td>
                                <td className="py-2 px-3">{record.year_of_establishment}</td>
                                <td className="py-2 px-3">{record.annual_renewal_year2021}</td>
                                <td className="py-2 px-3">{record.annual_renewal_year2022}</td>
                                <td className="py-2 px-3">{record.annual_renewal_year2023}</td>
                                <td className="py-2 px-3">{getLgaNameById(record.lga_id)}</td>
                                <td className="py-2 px-3">{record.school_email}</td>
                                <td className="py-2 px-3">{record.school_phone_nos}</td>
                                <td className="py-2 px-3">{record.proprietors_name}</td>
                                <td className="py-2 px-3">{record.total_girls_enrolment}</td>
                                <td className="py-2 px-3">{record.total_boys_enrolment}</td>
                                <td className="py-2 px-3">
                                    {(parseInt(record.total_girls_enrolment) || 0) +
                                        (parseInt(record.total_boys_enrolment) || 0)}
                                </td>
                                <td className="py-2 px-3">{record.no_teachers_female}</td>
                                <td className="py-2 px-3">{record.no_teachers_male}</td>
                                <td className="py-2 px-3">{record.no_teachers_pwd}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="flex justify-between items-center mt-4">
                <div>
                    <label className="mr-2">Page Size:</label>
                    <select
                        value={recordsPerPage}
                        onChange={handlePageSizeChange}
                        className="border border-gray-300 rounded p-1 text-sm"
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                    </select>
                </div>
                <div className="flex items-center">
                    <button
                        onClick={() => handlePageChange(1)}
                        disabled={currentPage === 1}
                        className={`px-2 py-1 rounded ${
                            currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                    >
                        <FaAngleDoubleLeft />
                    </button>
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`px-2 py-1 rounded ${
                            currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                    >
                        <FaAngleLeft />
                    </button>
                    <span className="mx-2">
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className={`px-2 py-1 rounded ${
                            currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                    >
                        <FaAngleRight />
                    </button>
                    <button
                        onClick={() => handlePageChange(totalPages)}
                        disabled={currentPage === totalPages}
                        className={`px-2 py-1 rounded ${
                            currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
                        }`}
                    >
                        <FaAngleDoubleRight />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SchoolReport;
