import React from 'react';
import NewsSection from './NewsSection';

const AboutUs = () => {
  return (
    <section id="about-us" className="pb-10 bg-gray-50">
      <div className="p-6 md:p-0 grid md:grid-flow-col bg-white">
        <div className="mb-5 md:mb-0 col-span-2 md:col-span-1">
          <NewsSection />
        </div>
        <div className="col-span-2 container">
          <div className="bg-white md:pr-16 md:pt-16">

            {/* e-Dossier Sliding Link */}
            <div className="text-center mb-8 hidden md:block">
              <a
                href="https://beqa.ng"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block bg-[#1ABC9C] text-white font-bold py-2 px-4 rounded-lg hover:bg-[#16A085] transition duration-200 animate-slide"
              >
                Visit Our e-Dossier Platform
              </a>
            </div>

            <div className="text-center mb-8">
              <h3 className="text-3xl font-semibold text-gray-700">Our Mission</h3>
              <p className="mt-4 text-gray-600">
                To introduce policies and programmes that will improve the quality of education in all schools below tertiary level through the:
              </p>
              <ul className="list-disc list-inside mt-4 text-left">
                <li>Standardization, monitoring and evaluation of both private and public schools</li>
                <li>Harmonization of quality assurance services in all parastatals under the state ministry of education</li>
              </ul>
            </div>

            <div className="text-center mb-8">
              <h3 className="text-3xl font-semibold text-gray-700">Our Vision</h3>
              <p className="mt-4 text-gray-600">
                To collaborate with other stakeholders in reducing the number of out-of-school children and ensure excellence in the education of every child in Benue State according to global standards and sustainable development goals.
              </p>
            </div>

            <div className="text-left">
              <h3 className="text-3xl font-semibold text-gray-700 mb-4">About Us</h3>
              <p className="mt-4 text-gray-600">
                In view of this administration’s commitment towards strengthening education at the Basic and Post-Basic level, there is compelling need to redefine the criteria for delivery of quality education in the State. To achieve this, there must be an efficient administrative structure for policy formulation, monitoring and implementation.
              </p>
              <p className="mt-4 text-gray-600">
                The State Government has therefore resolved to establish a Bureau that will be responsible for strengthening Government’s control and regulation of schools below tertiary level in the State.
              </p>
              <p className="mt-4 text-gray-600">
                The Bureau is expected to be responsible for standardization, monitoring and evaluation of both private and public schools; and other responsibilities that are necessary to strengthen quality control and regulation of schools below tertiary level in the State.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
