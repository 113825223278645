import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const RevenuePerformanceChart = ({ data }) => {
  if (!data || data.length === 0) return <p>No data available</p>;

  const labels = data.map(item => item.monthYear);
  const totalRevenues = data.map(item => item.totalRevenue);
  const totalInvoices = data.map(item => item.totalInvoices);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Total Revenue',
        data: totalRevenues,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
        tension: 0.1,
        yAxisID: 'y1',
      },
      {
        label: 'Total Invoices',
        data: totalInvoices,
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        fill: true,
        tension: 0.1,
        yAxisID: 'y2',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y1: {
        type: 'linear',
        position: 'left',
        ticks: {
          beginAtZero: true,
          callback: (value) => `₦${value.toLocaleString()}`,
        },
        title: {
          display: true,
          text: 'Total Revenue',
        },
      },
      y2: {
        type: 'linear',
        position: 'right',
        ticks: {
          beginAtZero: true,
          callback: (value) => `${value.toLocaleString()}`,
        },
        title: {
          display: true,
          text: 'Total Invoices',
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  return (
    <div style={{ height: '300px' }}> 
      <Line data={chartData} options={options} height={300} />
    </div>
  );
};

export default RevenuePerformanceChart;
