import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaDownload } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import { NumericFormat } from 'react-number-format';
import SkeletonLoading from './SkeletonLoading';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { API_BASE_URL } from '../firebase/config';

const TransactionsReport = ({ profileData }) => {
    const [subheadId, setSubheadId] = useState('');
    const [subheads, setSubheads] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [nextPageToken, setNextPageToken] = useState(null);
    const [prevPageToken, setPrevPageToken] = useState(null);

    // Fetch dropdown options for subheads
    const fetchSubheadOptions = useCallback(async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/dropdown/subhead/oid?value=${profileData.organisation_id}`);
            if (response.status === 200) setSubheads(response.data.message || []);
        } catch (error) {
            console.error("Failed to fetch Subheads:", error);
            NotificationManager.error('Failed to load subheads. Please try again.', 'Error');
        }
    }, []);

    // Fetch transactions data
    const fetchData = useCallback(
        async (token) => {
            try {
                setIsLoading(true);
                setTransactions([]);
                const filters = [
                    { field: 'cardResponseCode', operator: '==', value: '00' },
                    ...(subheadId ? [{ field: 'subheadId', operator: '==', value: subheadId }] : []),
                ];
                const response = await axios.post(
                    `${API_BASE_URL}/transactions/read`,
                    {
                        limit: pageSize,
                        direction: token ? (token === prevPageToken ? 'prev' : 'next') : 'next',
                        pageToken: token || null,
                        filters,
                    },
                    { headers: { oid: profileData.organisation_id } }
                );

                if (response.data.status === 200) {
                    setTransactions(response.data.result.transactions);
                    setNextPageToken(response.data.result.nextPageToken);
                    setPrevPageToken(response.data.result.prevPageToken);
                    setCurrentPage((prev) => (token === nextPageToken ? prev + 1 : token === prevPageToken ? prev - 1 : prev));
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
                NotificationManager.error('Failed to load transactions. Please try again.', 'Error');
            } finally {
                setIsLoading(false);
            }
        },
        [subheadId, pageSize, prevPageToken, nextPageToken, profileData.organisation_id]
    );

    useEffect(() => {
        fetchSubheadOptions();
    }, [fetchSubheadOptions]);

    const handleSearch = () => {
        setCurrentPage(1);
        fetchData(null);
    };

    const removeTimeFromDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return !isNaN(date) ? date.toISOString().split('T')[0] : dateString.split(' ')[0];
    };

    const csvData = useMemo(
        () => [
            [
                'S/N',
                'Station',
                'TxCode',
                'Payer',
                'Payer ID',
                'Note',
                'Operator',
                'Remittance ID',
                'Date Remitted',
                'Txn Date',
                'Entry Date',
                'Subhead',
                'Txn Amount',
                'Agency Amount',
                'Agent Amount',
                'Govt. Amount',
                'Card Status',
                'Response Code',
                'TID',
            ],
            ...transactions.map((transaction, index) => [
                index + 1,
                transaction.station,
                transaction.txCode,
                transaction.payerName,
                transaction.payerId,
                transaction.note,
                transaction.profileName,
                transaction.remittanceId,
                removeTimeFromDate(transaction.remittanceTime),
                removeTimeFromDate(transaction.txTime),
                removeTimeFromDate(transaction.entryDate),
                transaction.subhead,
                transaction.amount,
                transaction.agencyAmount,
                transaction.agentAmount,
                transaction.govtAmount,
                transaction.cardStatus,
                transaction.cardResponseCode,
                transaction.tid,
            ]),
        ],
        [transactions]
    );

    const handlePageChange = (direction) => {
        if (direction === 'next' && nextPageToken) fetchData(nextPageToken);
        else if (direction === 'prev' && prevPageToken) fetchData(prevPageToken);
    };

    return (
        <div className="container mx-auto p-4">
            <h2 className="text-xl font-bold mb-4">Transactions Report</h2>
            <div className="flex flex-col md:flex-row justify-end w-full mb-4">
                <select
                    value={subheadId}
                    onChange={(e) => {
                        setSubheadId(e.target.value);
                        setCurrentPage(1);
                    }}
                    className="border border-gray-300 rounded-md p-2 mb-2 md:mb-0 md:mr-2 w-full md:w-[220px]"
                    aria-label="Subhead Filter"
                >
                    <option value="">Select Subhead (optional)</option>
                    {subheads.map((subhead) => (
                        <option key={subhead.id} value={subhead.id}>
                            {subhead.name}
                        </option>
                    ))}
                </select>
                <button
                    onClick={handleSearch}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 w-full md:w-[100px]"
                >
                    Search
                </button>
            </div>

            <div className="flex justify-between items-center mb-4">
                <CSVLink
                    data={csvData}
                    filename="transactions_report.csv"
                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex items-center"
                >
                    <FaDownload className="mr-2" />
                    Download CSV
                </CSVLink>
            </div>
            {isLoading ? (
                <SkeletonLoading />
            ) : (

                <div className="overflow-x-auto">
                    <table className="whitespace-nowrap min-w-full bg-white border border-gray-300 shadow-md">
                        <thead className="bg-gray-100">
                            <tr>
                                {[
                                    'S/N',
                                    'Station',
                                    'TxCode',
                                    'Payer',
                                    'Payer ID',
                                    'Note',
                                    'Operator',
                                    'Remittance ID',
                                    'Date Remitted',
                                    'Txn Date',
                                    'Entry Date',
                                    'Subhead',
                                    'Txn Amount',
                                    'Agency Amount',
                                    'Agent Amount',
                                    'Govt. Amount',
                                    'Card Status',
                                    'Response Code',
                                    'TID',
                                ].map((heading, idx) => (
                                    <th key={idx} className="py-2 px-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                        {heading}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {transactions.length === 0 ? (
                                <tr>
                                    <td colSpan={20} className="py-4 text-center text-gray-500">
                                        No matching records found.
                                    </td>
                                </tr>
                            ) : (
                                transactions.map((transaction, index) => (
                                    <tr key={transaction.id} className={`hover:bg-gray-100 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                                        <td className="py-2 px-3 border-b text-sm">{(currentPage - 1) * pageSize + index + 1}</td>
                                        <td className="py-2 px-3 border-b text-sm">{transaction.station}</td>
                                        <td className="py-2 px-3 border-b text-sm">{transaction.txCode}</td>
                                        <td className="py-2 px-3 border-b text-sm">{transaction.payerName}</td>
                                        <td className="py-2 px-3 border-b text-sm">{transaction.payerId}</td>
                                        <td className="py-2 px-3 border-b text-sm">{transaction.note}</td>
                                        <td className="py-2 px-3 border-b text-sm">{transaction.profileName}</td>
                                        <td className="py-2 px-3 border-b text-sm">{transaction.remittanceId}</td>
                                        <td className="py-2 px-3 border-b text-sm">{removeTimeFromDate(transaction.remittanceTime)}</td>
                                        <td className="py-2 px-3 border-b text-sm">{removeTimeFromDate(transaction.txTime)}</td>
                                        <td className="py-2 px-3 border-b text-sm">{removeTimeFromDate(transaction.entryDate)}</td>
                                        <td className="py-2 px-3 border-b text-sm">{transaction.subhead}</td>
                                        <td className="py-2 px-3 border-b text-sm">
                                            <NumericFormat value={transaction.amount} displayType="text" thousandSeparator prefix="₦" decimalScale={2} fixedDecimalScale />
                                        </td>
                                        <td className="py-2 px-3 border-b text-sm">{transaction.agencyAmount}</td>
                                        <td className="py-2 px-3 border-b text-sm">{transaction.agentAmount}</td>
                                        <td className="py-2 px-3 border-b text-sm">{transaction.govtAmount}</td>
                                        <td className="py-2 px-3 border-b text-sm">{transaction.cardStatus}</td>
                                        <td className="py-2 px-3 border-b text-sm">{transaction.cardResponseCode}</td>
                                        <td className="py-2 px-3 border-b text-sm">{transaction.tid}</td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            )}

            <div className="flex justify-between items-center mt-4">
                <div>
                    <label className="mr-2">Page Size:</label>
                    <select
                        value={pageSize}
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                            setCurrentPage(1);
                        }}
                        className="border border-gray-300 rounded p-1 text-sm"
                        aria-label="Page Size Selector"
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                <div className="flex space-x-2">
                    <button
                        onClick={() => handlePageChange('prev')}
                        disabled={!prevPageToken}
                        className="disabled:opacity-50"
                        aria-label="Previous Page"
                    >
                        <FaAngleDoubleLeft />
                    </button>
                    <button
                        onClick={() => handlePageChange('prev')}
                        disabled={!prevPageToken}
                        className="disabled:opacity-50"
                        aria-label="Previous Page"
                    >
                        <FaAngleLeft />
                    </button>
                    <button
                        onClick={() => handlePageChange('next')}
                        disabled={!nextPageToken}
                        className="disabled:opacity-50"
                        aria-label="Next Page"
                    >
                        <FaAngleRight />
                    </button>
                    <button
                        onClick={() => handlePageChange('next')}
                        disabled={!nextPageToken}
                        className="disabled:opacity-50"
                        aria-label="Next Page"
                    >
                        <FaAngleDoubleRight />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TransactionsReport;
