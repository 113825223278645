import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaDownload } from 'react-icons/fa';
import SkeletonLoading from './SkeletonLoading';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import DateRangePicker from './DateRangePicker'; // Ensure you have a DateRangePicker component
import { API_BASE_URL } from '../firebase/config';

const TransactionSummaryReport = ({ profileData }) => {
  const [records, setRecords] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const today = new Date();
    const yearAgo = new Date();
    yearAgo.setFullYear(today.getFullYear() - 1);

    setStartDate(`${yearAgo.getFullYear()}-${String(yearAgo.getMonth()).padStart(2, '0')}`);
    setEndDate(`${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}`);
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      fetchTransactionSummary();
    }
  }, [startDate, endDate]);

  const fetchTransactionSummary = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/report/profile-remittance`, {
        headers: { oid: profileData.organisation_id },
        params: { startDate, endDate },
      });
      if (response.status === 200 && response.data.message) {
        setRecords(response.data.message);
      } else {
        NotificationManager.error('Failed to fetch transaction summary.');
      }
    } catch (error) {
      NotificationManager.error('An error occurred while fetching data.');
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = useMemo(
    () => records.slice(indexOfFirstRecord, indexOfLastRecord),
    [records, indexOfFirstRecord, indexOfLastRecord]
  );
  const totalPages = Math.ceil(records.length / recordsPerPage);

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handlePageSizeChange = (event) => {
    setRecordsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const csvData = useMemo(() => {
    return [
      ['S/N', 'Station', 'Profile', 'Total Amount', 'Card Amount', 'Cash Amount', 'Remitted', 'Unremitted'],
      ...records.map((record, index) => [
        index + 1,
        record.station,
        record.profile,
        record.amount,
        record.amountCard,
        record.amountCash,
        record.cashRemitted,
        record.cashUnremitted,
      ]),
    ];
  }, [records]);

  const handleDownloadCSV = () => {
    const csvContent = csvData.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'transaction_summary.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDateChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  if (isLoading) return <SkeletonLoading />;

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">Transaction Summary Report</h2>

      {/* Date Range Picker */}
      <div className="flex justify-end mb-4">
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChange={handleDateChange}
          className="mb-2 md:mb-0 md:mr-2"
        />
      </div>

      {/* CSV Download */}
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={handleDownloadCSV}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 inline-flex items-center"
        >
          <FaDownload className="mr-2" />
          Download CSV
        </button>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="whitespace-nowrap min-w-full bg-white border border-gray-300 shadow-md text-sm">
          <thead className="bg-gray-100">
            <tr>
              {['S/N', 'Station', 'Profile', 'Total Amount', 'Card Amount', 'Cash Amount', 'Remitted', 'Unremitted'].map(
                (header, idx) => (
                  <th
                    key={idx}
                    className="py-2 px-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider border-b"
                  >
                    {header}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {currentRecords.length === 0 ? (
              <tr>
                <td colSpan={8} className="py-4 text-center text-gray-500">
                  No data available.
                </td>
              </tr>
            ) : (
              currentRecords.map((record, index) => (
                <tr
                  key={record.id}
                  className={`hover:bg-gray-100 ${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'}`}
                >
                  <td className="py-2 px-3">{(currentPage - 1) * recordsPerPage + index + 1}</td>
                  <td className="py-2 px-3">{record.station}</td>
                  <td className="py-2 px-3">{record.uname}</td>
                  <td className="py-2 px-3">{record.transactionsAmount}</td>
                  <td className="py-2 px-3">{record.cardTransactionsAmount}</td>
                  <td className="py-2 px-3">{record.cashTransactionsAmount}</td>
                  <td className="py-2 px-3">{record.cashRemittancesAmount}</td>
                  <td className="py-2 px-3">{record.pendingRemittace}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <div>
          <label className="mr-2">Records per page:</label>
          <select
            value={recordsPerPage}
            onChange={handlePageSizeChange}
            className="border border-gray-300 rounded p-1 text-sm"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
          </select>
        </div>
        <div className="flex items-center">
          <button
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
            className={`px-2 py-1 rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <FaAngleDoubleLeft />
          </button>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-2 py-1 rounded ${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <FaAngleLeft />
          </button>
          <span className="px-4">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className={`px-2 py-1 rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <FaAngleRight />
          </button>
          <button
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
            className={`px-2 py-1 rounded ${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <FaAngleDoubleRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransactionSummaryReport;
