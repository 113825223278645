import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import SkeletonLoading from './SkeletonLoading';
import { NumericFormat } from 'react-number-format';
import { CSVLink } from 'react-csv';
import { FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight, FaDownload } from 'react-icons/fa';
import DateRangePicker from './DateRangePicker';
import { API_BASE_URL } from '../firebase/config';

const MonthlyProfileReport = ({ profileData }) => {
  const [reportData, setReportData] = useState(null);
  const [error, setError] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  // Default dates logic is always called in useEffect
  useEffect(() => {
    const today = new Date();
    const yearAgo = new Date();
    yearAgo.setFullYear(today.getFullYear() - 1);
    setStartDate(`${yearAgo.getFullYear()}-${String(yearAgo.getMonth() + 1).padStart(2, '0')}`);
    setEndDate(`${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}`);
  }, []);

  // Fetch data when startDate or endDate changes
  useEffect(() => {
    if (startDate && endDate) {
      fetchDataReport();
    }
  }, [startDate, endDate]);

  // Fetch report data
  const fetchDataReport = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/report/profile-monthly`, {
        params: { startDate, endDate },
        headers: { oid: profileData.organisation_id },
      });

      if (response.data.success) {
        setReportData(response.data.message);
        setError('');
      } else {
        setError('Failed to fetch profile report data');
      }
    } catch (err) {
      setError('An error occurred while fetching the profile report');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Generate CSV data
  const csvData = useMemo(() => {
    if (!reportData?.data) return [];
    return Object.keys(reportData.data).map((profile, idx) => ({
      SN: idx + 1,
      Profile: profile,
      ...reportData.data[profile],
    }));
  }, [reportData]);

  // Total items and pagination logic
  const totalItems = reportData?.data ? Object.keys(reportData.data).length : 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;

  // Current data for the table
  const currentData = useMemo(() => {
    if (!reportData?.data) return [];
    return Object.keys(reportData.data).slice(startIndex, startIndex + itemsPerPage);
  }, [reportData, startIndex, itemsPerPage]);

  // Calculate totals
  const totals = useMemo(() => {
    if (!reportData?.data) return {};
    return Object.keys(reportData.data).reduce((acc, profile) => {
      Object.entries(reportData.data[profile]).forEach(([key, value]) => {
        acc[key] = (acc[key] || 0) + value;
      });
      return acc;
    }, {});
  }, [reportData]);

  // Date range change handler
  const handleDateChange = ({ startDate, endDate }) => {
    if (new Date(startDate) > new Date(endDate)) {
      setError('Start date cannot be after end date');
      return;
    }
    setError('');
    setStartDate(startDate);
    setEndDate(endDate);
  };

  // Items per page change handler
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page when items per page changes
  };

  // Page navigation handler
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (error) {
    return (
      <div className="text-red-500">
        {error}
        <button onClick={fetchDataReport} className="ml-2 text-blue-500 underline">
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">Profile Monthly Report</h2>

      <div className="flex justify-end mb-4">
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onChange={handleDateChange}
          className="mb-2 md:mb-0 md:mr-2 w-full md:w-[220px]"
        />
      </div>

      <CSVLink
        data={csvData}
        filename="monthly_profile_report.csv"
        className="bg-green-500 text-white px-4 py-2 rounded mb-4 inline-flex items-center hover:bg-green-600"
      >
        <FaDownload className="mr-2" /> Download CSV
      </CSVLink>

      <div className="overflow-x-auto">
        <table className="whitespace-nowrap min-w-full bg-white border border-gray-300 shadow-md">
          <thead>
            <tr>
              <th className="py-3 px-4 text-left border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">
                SN
              </th>
              {reportData?.header.map((headerItem, index) => (
                <th
                  key={index}
                  className="py-3 px-4 text-left border-b-2 border-gray-200 bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider"
                >
                  {headerItem}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={reportData?.header.length + 1 || 1} className="py-3 text-center">
                  <SkeletonLoading />
                </td>
              </tr>
            ) : currentData.length === 0 ? (
              <tr>
                <td colSpan={reportData?.header.length + 1 || 1} className="py-3 text-center">
                  No data available.
                </td>
              </tr>
            ) : (
              currentData.map((profile, idx) => (
                <tr key={idx} className={`hover:bg-gray-100 ${idx % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                  <td className="py-3 px-4 text-left border-b border-gray-200 text-sm whitespace-nowrap">
                    {startIndex + idx + 1}
                  </td>
                  <td className="py-3 px-4 text-left border-b border-gray-200 text-sm whitespace-nowrap">
                    {profile}
                  </td>
                  {Object.values(reportData.data[profile]).map((value, index) => (
                    <td key={index} className="py-3 px-4 border-b border-gray-200 text-sm whitespace-nowrap">
                      <NumericFormat value={value} displayType="text" thousandSeparator prefix="₦" decimalScale={2} fixedDecimalScale />
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
          {totalItems > 0 && (
            <tfoot>
              <tr className="bg-gray-100">
                <td className="py-3 px-4 text-left font-bold text-lg" colSpan={2}>
                  Total
                </td>
                {Object.keys(totals).map((key, index) => (
                  <td
                    key={index}
                    className="py-3 px-4 border-t border-gray-200 text-sm whitespace-nowrap font-bold text-lg text-green-600"
                  >
                    <NumericFormat value={totals[key]} displayType="text" thousandSeparator prefix="₦" decimalScale={2} fixedDecimalScale />
                  </td>
                ))}
              </tr>
            </tfoot>
          )}
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <div>
          <label className="mr-2">Items per page:</label>
          <select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            className="border border-gray-300 rounded p-1"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </select>
        </div>
        <div className="flex items-center">
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(1)}
            className={`${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <FaAngleDoubleLeft />
          </button>
          <button
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className={`${currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <FaAngleLeft />
          </button>
          <span className="px-2 text-sm">
            {currentPage} of {totalPages}
          </span>
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className={`${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <FaAngleRight />
          </button>
          <button
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(totalPages)}
            className={`${currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <FaAngleDoubleRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MonthlyProfileReport;
