import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { useHistory } from 'react-router-dom';

const ProfileDropdown = ({ profile }) => {
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                localStorage.clear();
                history.push('/signin');
            })
            .catch((error) => {
                console.error('Sign out error', error);
            });
    };

    return (
        <div className="relative inline-block text-left">
            <div>
                <button
                    type="button"
                    className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-[#2C3E50] rounded hover:bg-[#1ABC9C] focus:outline-none"
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onClick={toggleDropdown}
                >
                    <div className="grid justify-items-start">
                        <span className="ml-2">{profile ? profile.name : 'Hello'}</span>
                    </div>
                    <svg
                        className="ml-2 -mr-1 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 9.293a1 1 0 011.414 0L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg>
                </button>
            </div>

            {isOpen && (
                <div
                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-[#34495E] ring-1 ring-black ring-opacity-5"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex="-1"
                >
                    <div className="py-1" role="none">
                        <Link
                            to="#"
                            className="block px-4 py-2 text-sm text-white hover:bg-[#1ABC9C]"
                            role="menuitem"
                            tabIndex="-1"
                            id="menu-item-0"
                            onClick={handleLogout}
                        >
                            Logout
                        </Link>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProfileDropdown;
