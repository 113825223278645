import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight, FaDownload } from "react-icons/fa";
import { CSVLink } from "react-csv";
import SkeletonLoading from "./SkeletonLoading";
import { API_BASE_URL } from "../firebase/config";

const headers = [
  { label: "S/N", key: "serial" },
  { label: "Remittance ID", key: "id" },
  { label: "Entry Date", key: "entryDate" },
  { label: "User Name", key: "profile" },
  { label: "LGA", key: "lga" },
  { label: "Station", key: "station" },
  { label: "Amount", key: "amount" },
  { label: "Status", key: "status" },
];

const getRowClass = (status) => {
  switch (status) {
    case "PENDING":
      return "bg-yellow-100";
    case "PAID":
      return "bg-green-100";
    default:
      return "bg-white";
  }
};

const RemittanceReport = ({ profileData }) => {
  const [transactions, setTransactions] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [nextPageToken, setNextPageToken] = useState(null);
  const [prevPageToken, setPrevPageToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [userOptions, setUserOptions] = useState([]);
  const [error, setError] = useState(null);

  const fetchUserOptions = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/dropdown/profile/agencyId?value=${profileData.agency_id}`
      );
      setUserOptions(response.data.message || []);
    } catch (error) {
      console.error("Error fetching profiles:", error);
      setError("Failed to load user options.");
    }
  };

  const fetchData = async (token = null) => {
    try {
      setIsLoading(true);
      setError(null);

      const filters = [];
      if (selectedStatus) filters.push({ field: "status", operator: "==", value: selectedStatus });
      if (selectedUserId) filters.push({ field: "uid", operator: "==", value: selectedUserId });

      const response = await axios.post(
        `${API_BASE_URL}/remittances/read`,
        {
          limit: pageSize,
          direction: token ? (token === prevPageToken ? "prev" : "next") : "next",
          pageToken: token,
          filters,
        },
        {
          headers: {
            oid: profileData.organisation_id,
          },
        }
      );

      const data = response.data;
      if (data.status === 200) {
        setTransactions(data.result.transactions || []);
        setNextPageToken(data.result.nextPageToken);
        setPrevPageToken(data.result.prevPageToken);
      } else {
        setError("Failed to fetch transactions.");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setError("An error occurred while fetching transactions.");
      setIsLoading(false);
    }
  };

  // Fetch user options and initial data load
  useEffect(() => {
    fetchUserOptions();
    fetchData();
  }, []);

  const handleSearch = () => {
    fetchData();
  };

  const handlePageChange = (direction) => {
    if (direction === "next" && nextPageToken) {
      fetchData(nextPageToken);
    } else if (direction === "prev" && prevPageToken) {
      fetchData(prevPageToken);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">Remittance Report</h2>

      {error && <div className="bg-red-100 text-red-700 p-3 rounded mb-4">{error}</div>}

      <div className="flex flex-col md:flex-row justify-end w-full mb-4">
        <select
          value={selectedStatus}
          onChange={(e) => setSelectedStatus(e.target.value)}
          className="border border-gray-300 rounded-md p-2 mb-2 md:mb-0 md:mr-2 bg-white focus:outline-none"
        >
          <option value="">Select Status (optional)</option>
          <option value="PENDING">Pending</option>
          <option value="PAID">Paid</option>
        </select>

        <select
          value={selectedUserId}
          onChange={(e) => setSelectedUserId(e.target.value)}
          className="border border-gray-300 rounded-md p-2 mb-2 md:mb-0 md:mr-2 bg-white focus:outline-none"
        >
          <option value="">Select Cashier (optional)</option>
          {userOptions.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name}
            </option>
          ))}
        </select>

        <button
          onClick={handleSearch}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
        >
          Search
        </button>
      </div>

      <CSVLink
        data={transactions}
        headers={headers}
        filename={"remittance_data.csv"}
        className="bg-green-500 text-white px-4 py-2 rounded mb-4 inline-flex items-center hover:bg-green-600"
      >
        <FaDownload className="mr-2" /> Download CSV
      </CSVLink>

      {isLoading ? (
        <SkeletonLoading />
      ) : transactions.length === 0 ? (
        <p className="text-gray-500">No transactions found.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="whitespace-nowrap min-w-full bg-white border border-gray-300 shadow-md">
            <thead>
              <tr>
                {headers.map((header) => (
                  <th key={header.key} className="py-3 px-4 text-left border-b bg-gray-100 text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {transactions.map((transaction, index) => (
                <tr key={transaction.id} className={`hover:bg-gray-100 ${getRowClass(transaction.status)}`}>
                  <td className="py-3 px-4 border-b text-sm">{index + 1}</td>
                  {headers.slice(1).map((header) => (
                    <td key={header.key} className="py-3 px-4 border-b text-sm">
                      {transaction[header.key]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="flex justify-between items-center mt-6">
        <label className="flex items-center">
          Items per page:
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="ml-2 p-1 border border-gray-300 rounded"
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </select>
        </label>
        <div className="flex space-x-2">
          <button
            onClick={() => handlePageChange("prev")}
            disabled={!prevPageToken}
            className="disabled:opacity-50"
          >
            <FaAngleLeft />
          </button>
          <button
            onClick={() => handlePageChange("next")}
            disabled={!nextPageToken}
            className="disabled:opacity-50"
          >
            <FaAngleRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemittanceReport;
